// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, onMounted, watch } from 'vue'

import { colPadding, convertRemToPixels, heightInPixelsFromRows, rowPadding, rowSize } from '@/common/shared.js'
import styleVariables from '@/common/shared.scss'


export default {
  setup({ isDragging, top, left, heightInPixels, widthInPixels, fullscreen, props }) {
    // const img = new Image()

    const setHeightInPixels = (toolHeightInRows) => {
      heightInPixels.value = heightInPixelsFromRows(toolHeightInRows)
    }

    const setLeft = (toolCol, columnSize) => {  // toolCol is column number (0-11).
      left.value = colPadding + columnSize * toolCol
    }

    const setTop = (toolRow) => {
      if (isDragging.value) { return }
      top.value = rowSize * toolRow
    }

    const setWidthInPixels = (toolWidthInColumns, columnSize) => {
      widthInPixels.value = columnSize * toolWidthInColumns - 2 * colPadding
    }

    const calcAbsoluteScreenPosition = (newValue) => {
      if (isDragging.value) { return }
      setHeightInPixels(newValue.toolHeightInRows)
      setLeft(newValue.toolCol, newValue.columnSize)
      setTop(newValue.toolRow)
      setWidthInPixels(newValue.toolWidthInColumns, newValue.columnSize)
    }

    const topPosition = -1 * (convertRemToPixels(parseFloat(styleVariables.headerHeight)) + rowPadding)
    const body = document.querySelector('body')
    const updateScreenPosition = (newValue) => {
      if (isDragging.value) { return }
      if (fullscreen.value) {
        body.style.overflowY = 'hidden'
        heightInPixels.value = window.innerHeight
        left.value = 0
        top.value = topPosition
        widthInPixels.value = window.innerWidth - 6
        return
      }
      body.style.overflowY = null
      calcAbsoluteScreenPosition(newValue)
    }

    const forceRedraw = computed(() => {
      return () => {
        updateScreenPosition(props)
      }
    })
    watch(props, (newValue) => updateScreenPosition(newValue))

    let preserveWindowScrollY = 0
    watch(fullscreen, (newValue) => {
      if (newValue) {
        preserveWindowScrollY = window.scrollY
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'auto',
        })
      } else {
        window.scrollTo({
          top: preserveWindowScrollY,
          left: 0,
          behavior: 'auto',
        })
      }
      updateScreenPosition(props)
    })

    onMounted(() => {
      updateScreenPosition(props)
    })

    return {
      forceRedraw,
    }
  },
}
