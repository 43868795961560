// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { store } from '@/store'

export default {
  setup(doCommand, refreshToolPositions) {
    const onToolClose = ({ toolId }) => {
      const toolIndex = store.getters.toolsMapIndex[toolId]
      const tool = store.getters.tools[toolIndex]
      const currentScrollY = window.scrollY
      doCommand({
        do: () => {
          store.dispatch('deleteToolAtIndex', toolIndex)
          refreshToolPositions()
        },
        undo: () => {
          store.dispatch('addToolAtIndex', { tool, toolIndex })
          refreshToolPositions()
          window.scrollTo({
            top: currentScrollY,
            left: 0,
            behavior: 'smooth',
          })
        },
      })
    }

    return {
      onToolClose,
    }
  },
}
