<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 448"
    :fill="modalIconColor"
  >
    <title>{{ tr('Return tool to original size') }}</title>
    <path d="M224 200v-95.005c0-21.382 25.85-32.09 40.97-16.971l27.704 27.704L404.889 3.515c4.686-4.686 12.284-4.686 16.97 0l22.628 22.627c4.686 4.686 4.686 12.284 0 16.97L332.272 155.327l27.704 27.704c15.119 15.119 4.41 40.97-16.971 40.97H248c-13.255 0-24-10.745-24-24zM43.112 444.485l112.213-112.213 27.704 27.704c15.12 15.119 40.97 4.41 40.97-16.971V248c0-13.255-10.744-24-24-24h-95.004c-21.382 0-32.09 25.85-16.971 40.97l27.704 27.705L3.515 404.888c-4.686 4.686-4.686 12.284 0 16.97l22.627 22.628c4.686 4.686 12.284 4.686 16.97-.001z" />
  </svg>
</template>

<script>
// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, inject } from 'vue'
import { useStore } from 'vuex'

import { themeColors } from '@/common/shared.js'

export default {
  setup() {
    const store = useStore()

    const modalIconColor = computed(() => {
      return themeColors[store.getters.currentThemeName].toolIconColor
    })

    const tr = inject('tr')

    return {
      modalIconColor,
      tr,
    }
  },
}
</script>
