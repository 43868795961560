// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, ref } from 'vue'

export default {
  setup(initialHistory) {
    const commandHistory = ref(initialHistory)

    const doCommand = (cmd) => {
      commandHistory.value.push(cmd)
      cmd.do()
    }

    const undoDisabled = computed(() => {
      return commandHistory.value.length === 0
    })

    const undoLastCommand = () => {
      if (commandHistory.value.length === 0) { return }
      const cmd = commandHistory.value.pop()
      cmd.undo()
    }

    return {
      doCommand,
      undoDisabled,
      undoLastCommand,
    }
  },
}
