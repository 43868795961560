<template>
  <find-patient-modal :open="findPatientModalOpen" @close="onCloseFindPatientModal">
  </find-patient-modal>
  <add-tools-modal :open="addToolsModalOpen" @close="onCloseAddToolsModal">
  </add-tools-modal>
  <the-header :anonymized="anonymized">
    <div class="patient-info" v-if="featureToggles.clinicianView && isValidSession && !featureToggles.upennBuild">
      <div class="inner-patient-info" :style="patientInfoStyle" @click.prevent="onOpenFindPatientModal" role="button"
        tabindex="0" @keyup.enter="onOpenFindPatientModal" :title="fullPatientInfo"><svg class="search-icon"
          v-if="canClearOrSelectPatient()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
          :style="patientInfoIconStyle">
          <!-- Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
          <path
            d="m508.488 468.887-121.4-121.4c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136 0-114.9-93.1-208-208-208s-208 93.1-208 208 93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zm-300.5-100.9c-88.4 0-160-71.6-160-160s71.6-160 160-160 160 71.6 160 160-71.6 160-160 160z" />
        </svg>{{ patientInfo }}</div>
      <svg class="clear-patient-icon" v-if="showClearPatient" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320.005 320.005" :style="patientInfoIconStyle" @click.prevent="clearPatientInfo" role="button"
        tabindex="0" @keyup.enter="clearPatientInfo">
        <title>{{ tr('Deselect patient') }}</title>
        <!-- Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
        <path
          d="m207.597 160.002 107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0l-107.71 107.73L52.278 4.683c-6.23-6.23-16.34-6.23-22.58 0l-25.02 25.02c-6.23 6.23-6.23 16.34 0 22.58l107.72 107.72-107.72 107.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0l107.71-107.73 107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58z" />
      </svg>
    </div>
    <add-tools-icon class="add-tools session-header-icon" v-if="isValidSession && !isSessionReadOnly"
      @click.prevent="onOpenAddToolsModal" role="button" tabindex="0" @keyup.enter="onOpenAddToolsModal">
    </add-tools-icon>
    <undo-icon class="undo session-header-icon" v-if="isValidSession && !isSessionReadOnly"
      @click.prevent="undoLastCommand" :button-disabled="undoDisabled" role="button" tabindex="0"
      @keyup.enter="undoLastCommand" :style="showUndo"></undo-icon>
  </the-header>
  <div class="session">
    <tool-manager :do-command="doCommand" :refresh-tool-positions="refreshToolPositions"
      :set-is-dragging-tool-id="setIsDraggingToolId" :set-tool-height-in-pixels="setToolHeightInPixels"
      ref="toolManagerRef">
      <template #default="slotProps">
        <base-tool :column-size="slotProps.columnSize" :window-height="slotProps.windowHeight"
          :window-width="slotProps.windowWidth" v-for="tool in tools" :key="tool.id" :session-owner-id="session.ownerId"
          :session-id="session.id" :tool-id="tool.id" :tool-name="tool.name" :tool-col="tool.col" :tool-row="tool.row"
          :tool-width-in-columns="tool.width" :tool-height-in-rows="tool.height" :payload="tool.payload"
          :on-tool-close="onToolClose" :on-tool-drag-start="onToolDragStart" :on-tool-drag="onToolDrag"
          :on-tool-drag-end="onToolDragEnd" :get-tool-scroll-y="getToolScrollY(tool)"
          :set-tool-scroll-y="setToolScrollY(tool)" @fullscreen="onFullScreenEvent(tool, $event)" class="base-tool">
          <component :is="toolComponent(tool.componentName)" :is-dragging-tool-id="isDraggingToolId"
            :is-fullscreen-tool-id="isFullscreenToolId" :payload="tool.payload" :session-owner-id="session.ownerId"
            :session-id="session.id" :tool-height-in-pixels="getToolHeightInPixels(tool.id)" :tool-id="tool.id"
            :tool-width-in-columns="tool.width" :tool-height-in-rows="tool.height"
            :window-height="slotProps.windowHeight"></component>
        </base-tool>
      </template>
    </tool-manager>
  </div>
</template>

<script>
// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, inject, onMounted, ref, watch, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { validate as uuidValidate, version as uuidVersion } from 'uuid'

import { refreshToolPositions } from '@/components/ui/ToolManager/rearrange.js'
import { addSpaceAfterComma, sanitizeUrlSegment, shortenId, themeColors } from '@/common/shared.js'
import AddToolsIcon from '@/components/ui/icons/AddToolsIcon.vue'
import AddToolsModal from '@/components/ui/AddToolsModal/AddToolsModal.vue'
import BaseTool from '@/components/ui/BaseTool/BaseTool.vue'
import FindPatientModal from '@/components/ui/FindPatientModal/FindPatientModal.vue'
import toolComponents from '@/components/tools/components.js'
import ToolManager from '@/components/ui/ToolManager/ToolManager.vue'
import UndoIcon from '@/components/ui/icons/UndoIcon.vue'
import { getBlackBoxSSRToolScrollY, setBlackBoxSSRToolScrollY } from '@/components/tools/BlackBoxSSRTool/scrollY.js'
import { getChatToolScrollY, setChatToolScrollY } from '@/components/tools/ChatTool/scrollY.js'
import { getNotesToolScrollY, setNotesToolScrollY } from '@/components/tools/NotesTool/scrollY.js'
import { getViewerToolScrollY, setViewerToolScrollY } from '@/components/tools/ViewerTool/scrollY.js'
import { getPatientToolScrollY, setPatientToolScrollY } from '@/components/tools/PatientInfoTool/scrollY.js'

import commandController from './commandController.js'
import toolClose from './toolClose.js'

export default {
  name: 'Session',
  components: {
    AddToolsIcon,
    AddToolsModal,
    BaseTool,
    FindPatientModal,
    ToolManager,
    UndoIcon,
  },
  props: {
    userName: {
      type: String,
      required: true,
    },
    sessionId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { toolComponent } = toolComponents.setup()

    const { doCommand, undoDisabled, undoLastCommand } = commandController.setup([])

    const store = useStore()
    const isValidSession = computed(() => !!store.getters.sessionId)
    const tutorialNext = inject('tutorialNext')

    if (store.getters.tools.length > 0) {
      refreshToolPositions()
    }

    const { onToolClose } = toolClose.setup(doCommand, refreshToolPositions)
    const onToolDragStart = (obj) => toolManagerRef.value.onToolDragStart(obj)
    const onToolDrag = (obj) => toolManagerRef.value.onToolDrag(obj)
    const onToolDragEnd = (obj) => toolManagerRef.value.onToolDragEnd(obj)
    const isDraggingToolId = ref('')
    const setIsDraggingToolId = (toolId) => {
      isDraggingToolId.value = toolId
    }
    const isFullscreenToolId = ref('')
    const setIsFullscreenToolId = (toolId) => {
      isFullscreenToolId.value = toolId
    }
    const toolHeightInPixels = ref({})
    const setToolHeightInPixels = (toolId, heightInPixels) => {
      toolHeightInPixels.value[toolId] = heightInPixels
    }
    const getToolHeightInPixels = (toolId) => toolHeightInPixels.value[toolId] || 0

    const toolManagerRef = ref(null)

    const setTranslationLanguage = inject('setTranslationLanguage')
    watchEffect(() => setTranslationLanguage(store.getters.profile.language))

    const session = computed(() => store.getters.session)
    const tools = computed(() => store.getters.tools)
    const anonymized = computed(() => store.getters.sessionAnonymized)

    const getUUIDs = (userName, sessionName) => {
      store.dispatch('clearSessionTools')
      // userName and sessionName are retrieved from the URL.
      userName = sanitizeUrlSegment(userName)
      sessionName = sanitizeUrlSegment(sessionName)
      const fallbackRedirectUrl = store.getters.mostRecentSessionUrlOrDashboard
      if (!userName || !sessionName) {
        console.log('Session.vue: Case 0: no user or session, redirect to fallback URL.')
        store.dispatch('routeToUrl', fallbackRedirectUrl)
        return
      }
      // There are 4 cases:
      // Case 1: This combo is owned by the current user: return UUIDs for lookup.
      // Case 2: This combo is shared with the current user: return UUIDs.
      // Case 3: The userName is the current user with a brand new session UUID.
      // Case 4: Attempt to access a session that might be shared with "anyone with the link".
      let session = store.getters.sessionsOwnedByMe.find((s) => {
        const ownerMatches = (s.ownerId === userName || sanitizeUrlSegment(s.ownerName) === userName)
        const sessionMatches = (s.id === sessionName || sanitizeUrlSegment(s.name) === sessionName)
        return ownerMatches && sessionMatches
      })
      if (session) {
        console.log('Session.vue: Case 1: user owns this session. Proceed.')
        const patient = store.getters.session.patient // only used for no-backend UX2, harmless otherwise.
        store.dispatch('getSavedSession', { requestingUserId: store.getters.userId, sessionOwnerId: session.ownerId, sessionId: session.id, patient })
        const lastAccessed = new Date().getTime()
        store.dispatch('setSessionLastAccessed', { ownerId: session.ownerId, sessionId: session.id, lastAccessed, publish: true })
        return
      }

      session = store.getters.sessionsSharedWithMe.find((s) => {
        const ownerMatches = (s.ownerId === userName || sanitizeUrlSegment(s.ownerName) === userName)
        const sessionMatches = (s.id === sessionName || sanitizeUrlSegment(s.name) === sessionName)
        return ownerMatches && sessionMatches
      })
      if (session) {
        console.log('Session.vue: Case 2: this session is shared with this user. Proceed.')
        store.dispatch('getSavedSession', { requestingUserId: store.getters.userId, sessionOwnerId: session.ownerId, sessionId: session.id })
        const lastAccessed = new Date().getTime()
        store.dispatch('setSessionLastAccessed', { ownerId: session.ownerId, sessionId: session.id, lastAccessed, publish: true })
        return
      }

      const isCurrentUser = (userName === store.getters.userId)
      const validSessionUUID = uuidValidate(sessionName) && uuidVersion(sessionName) === 4
      if (isCurrentUser && validSessionUUID) {
        console.log('Session.vue: Case 3: this is a brand new session for this user. Proceed.')
        return
      }

      console.log('Session.vue: Case 4: Attempt to access a session that might be shared with "anyone with the link".')
      store.dispatch('getSavedSession', { requestingUserId: store.getters.userId, sessionOwnerId: userName, sessionId: sessionName })
    }

    const findPatientModalOpen = ref(false)
    const onOpenFindPatientModal = () => {
      if (!canClearOrSelectPatient()) { return }
      findPatientModalOpen.value = true
    }
    const onCloseFindPatientModal = () => {
      findPatientModalOpen.value = false
    }

    const addToolsModalOpen = ref(false)
    const onOpenAddToolsModal = () => {
      addToolsModalOpen.value = true
      setTimeout(tutorialNext, 300)  // Required to allow modal to animate into place.
    }
    const onCloseAddToolsModal = () => {
      addToolsModalOpen.value = false
    }

    const tr = inject('tr')

    const featureToggles = computed(() => store.getters.featureToggles)
    const isSessionReadOnly = computed(() => store.getters.isSessionReadOnly)
    const isChartLaunchedPatient = computed(() => {
      const patient = store.getters.session.patient
      return !!(patient?.isChartLaunchedPatient)
    })

    const patientInfo = ref('')
    const fullPatientInfo = computed(() => {
      const patient = store.getters.session.patient
      return (patient && patient.id) ? patient.id : ''
    })
    const renderPatientInfo = (patient) => {
      if (!patient || !patient.id) { return tr.value('Find a patient') }
      if (anonymized.value) {
        return `${tr.value('MRN')} ${shortenId(patient.id)}`
      }
      return `${tr.value('MRN')} ${shortenId(patient.id)}: ${addSpaceAfterComma(patient.name)}`
    }
    watchEffect(() => {
      const patient = store.getters.session.patient
      patientInfo.value = renderPatientInfo(patient)
    })

    const clearPatientInfo = () => store.dispatch('setSessionPatient', { patient: {}, publish: true })
    const canClearOrSelectPatient = () => !(isChartLaunchedPatient.value) && !(isSessionReadOnly.value)

    const showClearPatient = computed(() => {
      const patient = store.getters.session.patient
      return patient && patient.id && canClearOrSelectPatient()
    })

    const patientInfoStyle = computed(() => {
      return {
        color: themeColors[store.getters.currentThemeName].baseHeaderTextColor,
        cursor: canClearOrSelectPatient() ? null : 'default',
        transform: canClearOrSelectPatient() ? null : 'scale(1.0)',
      }
    })

    const patientInfoIconStyle = computed(() => {
      return {
        fill: themeColors[store.getters.currentThemeName].baseHeaderIconColor,
      }
    })

    const undoStyling = computed(() => {
      if (undoDisabled.value) {
        return {
          cursorStyle: 'default',
          fillColor: 'grey', // stand-in until after theme color revisions
          scaleOnHover: 'scale(1)',
        }
      }
      return {
        cursorStyle: 'pointer',
        fillColor: themeColors[store.getters.currentThemeName].baseHeaderIconColor,
        scaleOnHover: 'scale(1.2)',
      }
    })
    const showUndo = computed(() => {
      return {
        cursor: undoStyling.value.cursorStyle,
        fill: undoStyling.value.fillColor,
        '--undo-hover-scale': undoStyling.value.scaleOnHover,
      }
    })

    watch(store.getters.profile.sessions, () => {
      if (!isValidSession.value) {
        getUUIDs(props.userName, props.sessionId)
        return
      }
    })

    const getToolScrollY = (tool) => {
      return () => {
        const parentDiv = document.querySelector(`div.tool-id-${tool.id}`)
        if (!parentDiv) {
          console.log(`ERROR: could not find tool parentDiv: id=${tool.id}`)
          return 0
        }
        switch (tool.componentName) {
          case 'BlackBoxSSRTool':
            return getBlackBoxSSRToolScrollY(parentDiv)
          case 'ChatTool':
            return getChatToolScrollY(parentDiv)
          case 'NotesTool':
            return getNotesToolScrollY(parentDiv)
          case 'PatientInfoTool':
            return getPatientToolScrollY(parentDiv)
          case 'ViewerTool':
            return getViewerToolScrollY(parentDiv)
          case 'ViewerTestTool':
            return 0
          default:
            console.log(`ERROR: Unknown component=${tool.componentName}: getToolScrollY(id=${tool.id})`)
        }
        return 0
      }
    }

    const setToolScrollY = (tool) => {
      return (scrollTop) => {
        const parentDiv = document.querySelector(`div.tool-id-${tool.id}`)
        if (!parentDiv) {
          console.log(`ERROR: could not find tool parentDiv: id=${tool.id}`)
          return 0
        }
        switch (tool.componentName) {
          case 'BlackBoxSSRTool':
            return setBlackBoxSSRToolScrollY(parentDiv, scrollTop)
          case 'ChatTool':
            return setChatToolScrollY(parentDiv, scrollTop)
          case 'NotesTool':
            return setNotesToolScrollY(parentDiv, scrollTop)
          case 'PatientInfoTool':
            return setPatientToolScrollY(parentDiv, scrollTop)
          case 'ViewerTool':
            return setViewerToolScrollY(parentDiv, scrollTop)
          case 'ViewerTestTool':
            return 0
          default:
            console.log(`ERROR: Unknown component=${tool.componentName}: setToolScrollY(id=${tool.id}, scrollTop=${scrollTop})`)
        }
      }
    }

    const onFullScreenEvent = (tool, value) => {
      setIsFullscreenToolId(value ? tool.id : '')
    }

    onMounted(() => {
      getUUIDs(props.userName, props.sessionId)
    })

    return {
      addToolsModalOpen,
      anonymized,
      clearPatientInfo,
      doCommand,
      featureToggles,
      findPatientModalOpen,
      fullPatientInfo,
      getToolHeightInPixels,
      getToolScrollY,
      isDraggingToolId,
      isFullscreenToolId,
      isSessionReadOnly,
      canClearOrSelectPatient,
      isValidSession,
      onCloseAddToolsModal,
      onCloseFindPatientModal,
      onOpenAddToolsModal,
      onOpenFindPatientModal,
      onFullScreenEvent,
      onToolClose,
      onToolDrag,
      onToolDragEnd,
      onToolDragStart,
      patientInfo,
      patientInfoIconStyle,
      patientInfoStyle,
      refreshToolPositions,
      session,
      setIsDraggingToolId,
      setToolHeightInPixels,
      setToolScrollY,
      showClearPatient,
      showUndo,
      toolComponent,
      toolManagerRef,
      tools,
      tr,
      undoDisabled,
      undoLastCommand,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/common/shared.scss';

.session {
  position: relative;
  top: 5rem;
  touch-action: none;
}

.base-tool {
  transition: all 0.15s linear;
}

.patient-info {
  display: flex;
  align-items: center;
  color: black;
  font-size: 1.2rem;
  padding: 8px;
  position: relative;
  right: 4rem;
}

.inner-patient-info {
  display: inline-block;
  cursor: pointer;

  &:hover,
  &:focus {
    transform: scale(1.2);
  }

  &:focus:not(:focus-visible) {
    transform: scale(1);

    &:hover {
      transform: scale(1.2);
    }
  }
}

.search-icon {
  display: inline-block;
  width: calc(#{$header-height} / 1.5);
  height: calc(#{$header-height} / 1.5);
  padding: calc(#{$header-height} / 6);
  vertical-align: middle;
}

.clear-patient-icon {
  display: inline-block;
  width: calc(#{$header-height} / 1.5);
  height: calc(#{$header-height} / 1.5);
  padding: calc(#{$header-height} / 6);
  margin: 0 calc(#{$header-height} / 4) calc(#{$header-height} / 2) calc(#{$header-height} / 4);
  transform: translateY(1.025rem);
  cursor: pointer;

  &:hover,
  &:focus {
    transform: scale(1.2) translateY(0.85rem);
  }

  &:focus:not(:focus-visible) {
    transform: scale(1) translateY(0.85rem);

    &:hover {
      transform: scale(1.2) translateY(0.85rem);
    }
  }
}

.inner-patient-info:hover~.clear-patient-icon {
  visibility: hidden;
}

.session-header-icon {
  display: inline-block;
  width: calc(#{$header-height} / 1.5);
  height: calc(#{$header-height} / 1.5);
  padding: calc(#{$header-height} / 6);
  margin: 0 calc(#{$header-height} / 8);

  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }
}

.undo {

  &:hover,
  &:focus {
    transform: var(--undo-hover-scale);
  }

  &:focus:not(:focus-visible) {
    transform: scale(1);

    &:hover {
      transform: var(--undo-hover-scale);
    }
  }
}

@media (max-width: 990px) {
  .search-icon {
    display: none;
  }

  .clear-patient-icon {
    display: none;
  }
}

@media (max-width: 870px) {
  .undo {
    display: none;
  }

  .session-header-icon {
    margin-right: 0;
    margin-left: 0;
  }
}

@media (max-width: 750px) {
  .patient-info {
    display: none;
  }

  .undo {
    display: inline-block;
  }

  .session-header-icon {
    margin: 0 calc(#{$header-height} / 8);
  }
}

@media (max-width: 360px) {
  .undo {
    display: none;
  }

  .session-header-icon {
    margin-right: 0;
    margin-left: 0;
  }
}

@media (max-width: 80px) {
  .add-tools {
    display: none;
  }
}
</style>
