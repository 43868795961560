<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 496 496"
    :fill="modalIconColor"
  >
    <title>{{ tr('Undo last action') }}</title>
    <path d="M20.485 20.485 72.65 72.65C117.525 27.767 179.515 0 247.999 0 384.66 0 496.1 111.525 496 248.185 495.9 385.067 384.905 496 248 496c-63.926 0-122.202-24.187-166.178-63.908-5.113-4.618-5.353-12.561-.482-17.433l19.738-19.738c4.498-4.498 11.753-4.785 16.501-.552C152.213 425.246 197.895 444 248 444c108.321 0 196-87.662 196-196 0-108.321-87.662-196-196-196-54.163 0-103.157 21.923-138.614 57.386l54.128 54.129c7.56 7.56 2.206 20.485-8.485 20.485H12c-6.627 0-12-5.373-12-12V28.971c0-10.691 12.926-16.045 20.485-8.486z" />
  </svg>
</template>

<script>
// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, inject } from 'vue'
import { useStore } from 'vuex'

import { themeColors } from '@/common/shared.js'

export default {
  setup() {
    const store = useStore()

    const modalIconColor = computed(() => {
      return themeColors[store.getters.currentThemeName].baseHeaderIconColor
    })

    const tr = inject('tr')

    return {
      modalIconColor,
      tr,
    }
  },
}
</script>
